import React from 'react';
import { Button, SvgIcon } from '@mui/material';

const CLIENT_ID = '12f6d7afe58b45c7b26b18b5a096e4da';
const REDIRECT_URI = 'https://metronome-5f0.pages.dev/callback';
const AUTH_ENDPOINT = 'https://accounts.spotify.com/authorize';
const RESPONSE_TYPE = 'token';

const SpotifyIcon = (props: any) => (
  <SvgIcon {...props}>
    <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z" />
  </SvgIcon>
);

const SpotifyAuth: React.FC = () => {
  const handleLogin = () => {
    window.location.href = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=playlist-read-private`;
  };

  return (
    <Button
      variant="contained"
      startIcon={<SpotifyIcon />}
      onClick={handleLogin}
      sx={{
        backgroundColor: '#1DB954',
        color: '#FFFFFF',
        fontWeight: 'bold',
        textTransform: 'none',
        borderRadius: '500px',
        padding: '10px 32px',
        fontSize: '14px',
        '&:hover': {
          backgroundColor: '#1ED760',
        },
      }}
    >
      Connect with Spotify
    </Button>
  );
};

export default SpotifyAuth;