import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Slider, Button, Box, Typography, Grid, Switch, FormControlLabel, IconButton, Divider, List, ListItem, ListItemText, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Container, useMediaQuery, useTheme, ThemeProvider, createTheme, CssBaseline, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import TapIcon from '@mui/icons-material/TouchApp';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import SpotifyAuth from './SpotifyAuth';
import PlaylistSelector from './PlaylistSelector';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// Add this new component
const NumberInput: React.FC<{
  label: string;
  value: number;
  onChange: (value: number) => void;
  onBlur?: () => void;
  min: number;
  max: number;
}> = ({ label, value, onChange, onBlur, min, max }) => {
  const handleIncrement = () => onChange(Math.min(value + 1, max));
  const handleDecrement = () => onChange(Math.max(value - 1, min));

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography>{label}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={handleDecrement} disabled={value <= min} size="small">
          <RemoveIcon fontSize="small" />
        </IconButton>
        <Typography 
          sx={{ mx: 1, minWidth: '30px', textAlign: 'center' }}
          onBlur={onBlur}
        >
          {value}
        </Typography>
        <IconButton onClick={handleIncrement} disabled={value >= max} size="small">
          <AddIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

export interface Song {
  id: string;
  title: string;
  artist: string;
  bpm: number;
  key: string;
  notes: string;
  spotifyUri?: string;
}

// Add this dummy data
const dummySongs: Song[] = [
  { id: '1', title: 'Stairway to Heaven', artist: 'Led Zeppelin', bpm: 82, key: 'Am', notes: 'Iconic guitar solo' },
  { id: '2', title: 'Bohemian Rhapsody', artist: 'Queen', bpm: 72, key: 'Bb', notes: 'Multiple tempo changes' },
  { id: '3', title: 'Sweet Child O\' Mine', artist: 'Guns N\' Roses', bpm: 126, key: 'D', notes: 'Slash\'s famous riff' },
  { id: '4', title: 'Smells Like Teen Spirit', artist: 'Nirvana', bpm: 117, key: 'F', notes: 'Grunge anthem' },
  { id: '5', title: 'Hotel California', artist: 'Eagles', bpm: 75, key: 'Bm', notes: 'Dual guitar solo' },
];

const Metronome: React.FC = () => {
  const [tempo, setTempo] = useState(120);
  const [isPlaying, setIsPlaying] = useState(false);
  const [beatsPerMeasure, setBeatsPerMeasure] = useState(4);
  const [currentBeat, setCurrentBeat] = useState(0); // Change initial value to 0
  const [runningBars, setRunningBars] = useState(1);
  const [mutingBars, setMutingBars] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [barCount, setBarCount] = useState(0);
  const [tempoTrainerMode, setTempoTrainerMode] = useState(false);

  const audioContext = useRef<AudioContext | null>(null);
  const nextNoteTime = useRef(0);
  const timerID = useRef<number | null>(null);

  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [playlists, setPlaylists] = useState<any[]>([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState<string | null>(null);

  const [setlist, setSetlist] = useState<Song[]>(dummySongs);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);

  const [openSongList, setOpenSongList] = useState(false);

  const [darkMode, setDarkMode] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const customTheme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
        },
      }),
    [darkMode]
  );

  useEffect(() => {
    const hash = window.location.hash;
    let token = window.localStorage.getItem("token");

    if (!token && hash) {
      token = hash.substring(1).split("&").find(elem => elem.startsWith("access_token"))?.split("=")[1] ?? null;

      window.location.hash = "";
      window.localStorage.setItem("token", token ?? "");
    }

    setAccessToken(token);
  }, []);

  const logout = () => {
    setAccessToken(null);
    window.localStorage.removeItem("token");
  };

  const fetchPlaylists = async () => {
    if (accessToken) {
      const response = await fetch('https://api.spotify.com/v1/me/playlists', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      setPlaylists(data.items);
    }
  };

  useEffect(() => {
    if (accessToken) {
      fetchPlaylists();
    }
  }, [accessToken]);

  const handlePlaylistSelect = async (playlistId: string) => {
    setSelectedPlaylist(playlistId);
    if (accessToken) {
      const response = await fetch(`https://api.spotify.com/v1/playlists/${playlistId}/tracks`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      const importedSongs: Song[] = data.items.map((item: any) => ({
        id: item.track.id,
        title: item.track.name,
        artist: item.track.artists.map((artist: any) => artist.name).join(', '),
        bpm: 120, // Default BPM, as Spotify doesn't provide BPM information
        key: '', // Default key, as Spotify doesn't provide key information
        notes: '',
        spotifyUri: item.track.id, // Add this line to store the Spotify track ID
      }));
      setSetlist(importedSongs);
      setCurrentSongIndex(0);
    }
  };

  const scheduleNote = useCallback((time: number, beat: number) => {
    if (!audioContext.current || isMuted) return;

    const osc = audioContext.current.createOscillator();
    const envelope = audioContext.current.createGain();

    osc.frequency.value = beat === 0 ? 1000 : 800; // High pitch for the first beat
    envelope.gain.value = 1;
    envelope.gain.exponentialRampToValueAtTime(1, time + 0.001);
    envelope.gain.exponentialRampToValueAtTime(0.001, time + 0.02);

    osc.connect(envelope);
    envelope.connect(audioContext.current.destination);

    osc.start(time);
    osc.stop(time + 0.03);
  }, [isMuted]);

  const nextNote = useCallback(() => {
    const secondsPerBeat = 60.0 / tempo;
    nextNoteTime.current += secondsPerBeat;

    setCurrentBeat((prevBeat: number) => {
      const nextBeat = (prevBeat + 1) % beatsPerMeasure;
      if (nextBeat === 0) {
        setBarCount((prevCount: number) => prevCount + 1);
      }
      return nextBeat;
    });
  }, [tempo, beatsPerMeasure]);

  const scheduler = useCallback(() => {
    if (!audioContext.current) return;

    while (nextNoteTime.current < audioContext.current.currentTime + 0.1) {
      scheduleNote(nextNoteTime.current, currentBeat);
      nextNote();
    }
    timerID.current = window.setTimeout(scheduler, 25);
  }, [scheduleNote, nextNote, currentBeat]);

  const startStop = useCallback(() => {
    if (isPlaying) {
      if (timerID.current) clearTimeout(timerID.current);
      setIsPlaying(false);
      setBarCount(0);
      setIsMuted(false);
      setCurrentBeat(0); // Reset to 0 instead of -1
    } else {
      if (!audioContext.current) {
        audioContext.current = new (window.AudioContext || (window as any).webkitAudioContext)();
      }
      nextNoteTime.current = audioContext.current.currentTime;
      setIsPlaying(true);
      setBarCount(0);
      setIsMuted(false);
      setCurrentBeat(0); // Start at 0 (first beat)
      scheduler();
    }
  }, [isPlaying, scheduler]);

  useEffect(() => {
    if (isPlaying) {
      scheduler();
    } else {
      if (timerID.current) clearTimeout(timerID.current);
    }
    return () => {
      if (timerID.current) clearTimeout(timerID.current);
    };
  }, [isPlaying, scheduler]);

  useEffect(() => {
    if (tempoTrainerMode && isPlaying) {
      const totalBars = runningBars + mutingBars;
      const barWithinCycle = barCount % totalBars;
      setIsMuted(barWithinCycle >= runningBars);
    } else {
      setIsMuted(false);
    }
  }, [tempoTrainerMode, isPlaying, barCount, runningBars, mutingBars]);

  const handleOpenSongList = () => {
    setOpenSongList(true);
  };

  const handleCloseSongList = () => {
    setOpenSongList(false);
  };

  const addNewSong = () => {
    const newSong: Song = {
      id: Date.now().toString(),
      title: 'New Song',
      artist: 'Unknown Artist',
      bpm: tempo,
      key: '',
      notes: ''
    };
    setSetlist([...setlist, newSong]);
  };

  const removeSong = (id: string) => {
    setSetlist(setlist.filter(song => song.id !== id));
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(setlist);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSetlist(items);
  };

  const handleExport = () => {
    const csvContent = "id,title,artist,bpm,key,notes\n" + setlist.map(song => 
      `${song.id},${song.title},${song.artist},${song.bpm},${song.key},${song.notes}`
    ).join("\n");
    const blob = new Blob([csvContent], { type: 'text/plain;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'setlist.txt');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleImportSetlist = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const content = e.target?.result as string;
          const lines = content.split('\n');
          const header = lines[0].split(',');
          if (header.join(',') !== 'id,title,artist,bpm,key,notes') {
            throw new Error('Invalid file format');
          }
          const importedSetlist: Song[] = lines.slice(1).map(line => {
            const [id, title, artist, bpm, key, notes] = line.split(',');
            return {
              id,
              title,
              artist,
              bpm: parseInt(bpm, 10),
              key,
              notes
            };
          });
          setSetlist(importedSetlist);
          setCurrentSongIndex(0);
          if (importedSetlist.length > 0) {
            setTempo(importedSetlist[0].bpm);
          }
          // Don't close the dialog after importing
          // handleCloseSongList();
        } catch (error) {
          console.error('Error parsing setlist file:', error);
          alert('Invalid setlist file. Please check the format and try again.');
        }
      };
      reader.readAsText(file);
    }
  };

  const handlePreviousSong = () => {
    if (currentSongIndex > 0) {
      setCurrentSongIndex(currentSongIndex - 1);
      setTempo(setlist[currentSongIndex - 1].bpm);
    }
  };

  const handleNextSong = () => {
    if (currentSongIndex < setlist.length - 1) {
      setCurrentSongIndex(currentSongIndex + 1);
      setTempo(setlist[currentSongIndex + 1].bpm);
    }
  };

  const handleDarkModeToggle = () => {
    setDarkMode(!darkMode);
  };

  const handleLoadToMetronome = () => {
    if (setlist.length > 0) {
      setTempo(setlist[0].bpm);
      setCurrentSongIndex(0);
    }
    handleCloseSongList();
  };

  const [editingField, setEditingField] = useState<Song | null>(null);
  const [tempEditValue, setTempEditValue] = useState<string | number>('');

  // Add this array of musical keys
  const musicalKeys = [
    'C', 'C#/Db', 'D', 'D#/Eb', 'E', 'F', 'F#/Gb', 'G', 'G#/Ab', 'A', 'A#/Bb', 'B',
    'Cm', 'C#m/Dbm', 'Dm', 'D#m/Ebm', 'Em', 'Fm', 'F#m/Gbm', 'Gm', 'G#m/Abm', 'Am', 'A#m/Bbm', 'Bm'
  ];

  const handleEditField = (id: string, field: string, value: string | number) => {
    const songToEdit = setlist.find(song => song.id === id);
    if (songToEdit) {
      if (isMobile) {
        setMobileEditingSong(songToEdit);
      } else {
        setEditingField(songToEdit);
        setTempEditValue(value);
      }
    }
  };

  // Add this new state for mobile editing
  const [mobileEditingSong, setMobileEditingSong] = useState<Song | null>(null);

  // Add this new function for mobile editing
  const handleMobileEditSave = () => {
    if (mobileEditingSong) {
      setSetlist(setlist.map(song => 
        song.id === mobileEditingSong.id ? mobileEditingSong : song
      ));
      setMobileEditingSong(null);
    }
  };

  const handleUpdateField = (id: string, field: string, value: string | number) => {
    setSetlist(setlist.map(song => 
      song.id === id ? { ...song, [field]: field === 'bpm' ? Number(value) : value } : song
    ));
    setEditingField(null);
  };

  const handleInputChange = (field: string, value: string | number) => {
    if (isMobile && mobileEditingSong) {
      setMobileEditingSong({ ...mobileEditingSong, [field]: value });
    } else if (editingField) {
      setEditingField({ ...editingField, [field]: value });
    }
  };

  const handleInputBlur = (id: string, field: string) => {
    handleUpdateField(id, field, tempEditValue);
  };

  const handleTempoChange = (newTempo: number) => {
    setTempo(Math.min(Math.max(newTempo, 40), 240));
  };

  // Add this back
  const lastTapTime = useRef<number | null>(null);
  const tapCount = useRef(0);
  const tapTempo = useRef(0);

  // Add this function back
  const handleTapTempo = () => {
    const now = performance.now();
    if (lastTapTime.current) {
      const timeDiff = now - lastTapTime.current;
      tapTempo.current += 60000 / timeDiff;
      tapCount.current++;

      if (tapCount.current >= 2) {
        const newTempo = Math.round(tapTempo.current / tapCount.current);
        setTempo(Math.min(Math.max(newTempo, 40), 240));
        tapTempo.current = 0;
        tapCount.current = 0;
      }
    }
    lastTapTime.current = now;

    // Reset tap tempo calculation after 2 seconds of inactivity
    setTimeout(() => {
      if (performance.now() - lastTapTime.current! > 2000) {
        lastTapTime.current = null;
        tapCount.current = 0;
        tapTempo.current = 0;
      }
    }, 2000);
  };

  const handlePlayInSpotify = () => {
    if (setlist[currentSongIndex] && setlist[currentSongIndex].spotifyUri) {
      window.open(`https://open.spotify.com/track/${setlist[currentSongIndex].spotifyUri}`, '_blank');
    }
  };

  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <Container maxWidth={isMobile ? 'sm' : isTablet ? 'md' : 'lg'}>
        <Box
          sx={{
            width: '100%',
            margin: 'auto',
            padding: { xs: 1, sm: 2, md: 3 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mb: 2 }}>
            <IconButton onClick={handleDarkModeToggle} color="inherit">
              {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </Box>

          <Typography variant={isMobile ? "h3" : "h2"} gutterBottom align="center">
            Live Metronome
          </Typography>

          <Typography variant={isMobile ? "h5" : "h4"} gutterBottom>
            {tempo} BPM
          </Typography>
          <Box sx={{ width: '100%', maxWidth: 500, display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => handleTempoChange(tempo - 1)} disabled={tempo <= 40}>
              <RemoveIcon />
            </IconButton>
            <Slider
              value={tempo}
              onChange={(_, newValue: number | number[]) => handleTempoChange(newValue as number)}
              min={40}
              max={240}
              valueLabelDisplay="auto"
              sx={{ mx: 2 }}
            />
            <IconButton onClick={() => handleTempoChange(tempo + 1)} disabled={tempo >= 240}>
              <AddIcon />
            </IconButton>
          </Box>
          <Box sx={{ my: 2, width: '100%', maxWidth: 300 }}>
            <NumberInput
              label="Beats per Measure"
              value={beatsPerMeasure}
              onChange={setBeatsPerMeasure}
              min={1}
              max={12}
            />
          </Box>
          
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2, flexWrap: 'wrap', gap: 2 }}>
            <Button
              variant="contained"
              color={isPlaying ? "secondary" : "primary"}
              onClick={startStop}
              startIcon={isPlaying ? <StopIcon /> : <PlayArrowIcon />}
            >
              {isPlaying ? 'Stop' : 'Start'}
            </Button>
            <Button variant="contained" onClick={handleTapTempo} startIcon={<TapIcon />}>
              Tap Tempo
            </Button>
          </Box>

          <Button variant="contained" onClick={handleOpenSongList} startIcon={<EditIcon />} sx={{ mt: 2 }}>
            Edit Song List
          </Button>

          {setlist.length > 0 && (
            <Box sx={{ my: 2, width: '100%', maxWidth: 500 }}>
              <Typography variant="h6">Current Song</Typography>
              <Typography>
                {setlist[currentSongIndex].title} - {setlist[currentSongIndex].artist}
              </Typography>
              <Typography>
                BPM: {setlist[currentSongIndex].bpm} | Key: {setlist[currentSongIndex].key}
              </Typography>
              <Typography>
                Notes: {setlist[currentSongIndex].notes}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                <Button
                  onClick={handlePreviousSong}
                  disabled={currentSongIndex === 0}
                  startIcon={<SkipPreviousIcon />}
                >
                  Previous
                </Button>
                <Button
                  onClick={handlePlayInSpotify}
                  startIcon={<OpenInNewIcon />}
                  disabled={!setlist[currentSongIndex].spotifyUri}
                >
                  Play in Spotify
                </Button>
                <Button
                  onClick={handleNextSong}
                  disabled={currentSongIndex === setlist.length - 1}
                  endIcon={<SkipNextIcon />}
                >
                  Next
                </Button>
              </Box>
              <Typography variant="h6" sx={{ mt: 2 }}>Upcoming Songs</Typography>
              <List>
                {setlist.slice(currentSongIndex + 1, currentSongIndex + 4).map((song, index) => (
                  <ListItem key={song.id}>
                    <ListItemText
                      primary={`${currentSongIndex + index + 2}. ${song.title}`}
                      secondary={`${song.artist} | BPM: ${song.bpm} | Key: ${song.key}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
          
          <Divider sx={{ my: 4, width: '100%' }} />

          <Box sx={{ my: 2, width: '100%', maxWidth: 300 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={tempoTrainerMode}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTempoTrainerMode(e.target.checked)}
                />
              }
              label="Tempo Trainer"
            />
            {tempoTrainerMode && (
              <>
                <NumberInput
                  label="Run Bars"
                  value={runningBars}
                  onChange={setRunningBars}
                  min={1}
                  max={99}
                />
                <Box sx={{ mt: 1 }}>
                  <NumberInput
                    label="Mute Bars"
                    value={mutingBars}
                    onChange={setMutingBars}
                    min={0}
                    max={99}
                  />
                </Box>
              </>
            )}
          </Box>

          <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <Typography variant="subtitle1" gutterBottom>
              Connect with Spotify
            </Typography>
            {!accessToken ? (
              <SpotifyAuth />
            ) : (
              <>
                <PlaylistSelector 
                  playlists={playlists} 
                  onPlaylistSelect={handlePlaylistSelect} 
                  selectedPlaylist={selectedPlaylist}
                />
                <Button
                  variant="text"
                  onClick={logout}
                  sx={{ mt: 1, color: 'text.secondary', fontSize: '0.875rem' }}
                >
                  Disconnect Spotify
                </Button>
              </>
            )}
          </Box>
        </Box>

        <Box sx={{ mt: 'auto', py: 2, textAlign: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            Created by Thorsten Sichtermann
          </Typography>
        </Box>

        <Dialog
          open={openSongList}
          onClose={handleCloseSongList}
          maxWidth="md"
          fullWidth
          fullScreen={isMobile}
        >
          <DialogTitle>Song List</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, flexWrap: 'wrap', gap: 1 }}>
              <Button variant="contained" onClick={addNewSong} startIcon={<AddIcon />}>
                Add Song
              </Button>
              <Button
                variant="contained"
                component="label"
                startIcon={<UploadFileIcon />}
              >
                Import
                <input
                  type="file"
                  hidden
                  onChange={handleImportSetlist}
                  accept=".txt"
                />
              </Button>
              <Button variant="contained" onClick={handleExport}>
                Export
              </Button>
              <Button variant="contained" onClick={handleLoadToMetronome} color="secondary">
                Load
              </Button>
            </Box>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="songs">
                {(provided) => (
                  <List {...provided.droppableProps} ref={provided.innerRef}>
                    {setlist.map((song, index) => (
                      <Draggable key={song.id} draggableId={song.id} index={index}>
                        {(provided) => (
                          <ListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            disablePadding
                          >
                            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                              <IconButton {...provided.dragHandleProps}>
                                <DragIndicatorIcon />
                              </IconButton>
                              <ListItemText
                                primary={song.title}
                                secondary={`${song.artist} | ${song.bpm} BPM | Key: ${song.key}`}
                                onClick={() => handleEditField(song.id, 'title', song.title)}
                                sx={{ flexGrow: 1, cursor: 'pointer' }}
                              />
                              <IconButton edge="end" aria-label="delete" onClick={() => removeSong(song.id)}>
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </ListItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSongList}>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog 
          open={!!editingField || !!mobileEditingSong} 
          onClose={() => {
            setEditingField(null);
            setMobileEditingSong(null);
          }}
          fullScreen={isMobile}
        >
          <DialogTitle>Edit Song</DialogTitle>
          <DialogContent>
            {(editingField || mobileEditingSong) && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <TextField
                  label="Title"
                  value={isMobile ? mobileEditingSong?.title : editingField?.title}
                  onChange={(e) => handleInputChange('title', e.target.value)}
                  fullWidth
                />
                <TextField
                  label="Artist"
                  value={isMobile ? mobileEditingSong?.artist : editingField?.artist}
                  onChange={(e) => handleInputChange('artist', e.target.value)}
                  fullWidth
                />
                <TextField
                  label="BPM"
                  type="number"
                  value={isMobile ? mobileEditingSong?.bpm : editingField?.bpm}
                  onChange={(e) => handleInputChange('bpm', Number(e.target.value))}
                  fullWidth
                />
                <TextField
                  label="Key"
                  value={isMobile ? mobileEditingSong?.key : editingField?.key}
                  onChange={(e) => handleInputChange('key', e.target.value)}
                  fullWidth
                />
                <TextField
                  label="Notes"
                  value={isMobile ? mobileEditingSong?.notes : editingField?.notes}
                  onChange={(e) => handleInputChange('notes', e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setEditingField(null);
              setMobileEditingSong(null);
            }}>
              Cancel
            </Button>
            <Button onClick={() => {
              if (isMobile && mobileEditingSong) {
                handleMobileEditSave();
              } else if (editingField) {
                setSetlist(setlist.map(song => 
                  song.id === editingField.id ? editingField : song
                ));
                setEditingField(null);
              }
            }} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
};

export default Metronome;