import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';

interface Playlist {
  id: string;
  name: string;
}

interface PlaylistSelectorProps {
  playlists: Playlist[];
  onPlaylistSelect: (playlistId: string) => void;
  selectedPlaylist: string | null;
}

const PlaylistSelector: React.FC<PlaylistSelectorProps> = ({ playlists, onPlaylistSelect, selectedPlaylist }) => {
  const handlePlaylistChange = (event: SelectChangeEvent<string>) => {
    onPlaylistSelect(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>Select a playlist</InputLabel>
      <Select value={selectedPlaylist || ''} onChange={handlePlaylistChange}>
        {playlists.map((playlist) => (
          <MenuItem key={playlist.id} value={playlist.id}>
            {playlist.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PlaylistSelector;